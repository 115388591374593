import { MPCITouchPoint } from "../typings/TouchPoint";
import { MPCCallLimiter } from "../helpers/call-limiter";
import { MPCIFlyoutContentController } from "../typings/iFlyoutContentController";
import { MPCFlyoutContentController } from "../PCF/flyout-content-controller";
import { MPCEventBus } from "../helpers/event-bus";
import { MPCDeviceDetectionHelper } from "../helpers/deviceDetectionHelper";
const ScaleIcon2021 = require("../../images/scale-2021.svg") as string;
const ScaleEmptyIcon = require("../../images/scale-empty.svg") as string;

export module MPCTileScaleButtonEx {
    import ITouchPoint = MPCITouchPoint.ITouchPoint;
    import EventBus = MPCEventBus.EventBus;
    import DeviceDetectionHelper = MPCDeviceDetectionHelper.DeviceDetectionHelper;

    export class TileScaleButtonEx implements ITouchPoint {
        private markerElement: HTMLElement;
        private touchPointElement: HTMLElement;
        private slug: string;
        private origin: string;
        private salesArticleVariantKey: string;
        private isMobileShop: boolean;
        private iFlyoutContentController: MPCIFlyoutContentController.IFlyoutContentController;
        private eventBus: EventBus;
        private deviceDetectionHelper : DeviceDetectionHelper;

        constructor(element: HTMLElement, salesArticleVariantKey: string, slug: string, origin: string, displayedInCategoryNavKey: string) {
            this.deviceDetectionHelper = DeviceDetectionHelper.instance;
            this.isMobileShop = this.deviceDetectionHelper.isMobileShop();
            this.iFlyoutContentController = MPCFlyoutContentController.FlyoutContentController.instance;
            this.eventBus = MPCEventBus.EventBus.instance;
            this.salesArticleVariantKey = salesArticleVariantKey;            
            this.slug = slug;
            this.origin = origin;

            this.markerElement = element;
            this.getTouchPoint();
        }

        update(oldSavKey: string, newSavKey: string): void {
            if (!this.touchPointElement) {
                return;
            }

            if (!oldSavKey) {
                // backwards compatibility - can be removed after ATS is deployed on PROD
                const selectedVariantElement: HTMLElement = this.touchPointElement.closest('.article-tile')
                    .querySelector('.colors .selected img');
                const thisButtonIsForSavKey: string = selectedVariantElement ? selectedVariantElement.getAttribute('data-colorid') : null;

                if (thisButtonIsForSavKey != newSavKey) {
                    // this event is received by all touchpoints on the site, 
                    // on categorypage, unrelated touchpoints need to ignore this
                    return;
                }
                this.salesArticleVariantKey = newSavKey;
                this.toggle();
            } else {
                // new way
                if (this.salesArticleVariantKey != oldSavKey) {
                    return;
                }

                this.salesArticleVariantKey = newSavKey;
                this.toggle();
            }
        }

        private getTouchPoint(): void {
            const scopeClass = this.markerElement.classList.contains('scope-ats')
                ? ' ats'
                : '';

            const forArticleTile: HTMLElement = document.createElement('div');
            forArticleTile.className = 'mpc-scope scale-tile-container touchpoint' + scopeClass;
            forArticleTile.setAttribute("data-savkey", this.salesArticleVariantKey);
            forArticleTile.setAttribute("data-item-origin", this.origin);

            const emptyIcon: HTMLElement = document.createElement('div');
            emptyIcon.className = 'comparison-empty-icon';

            const icon: HTMLElement = document.createElement('div');
            icon.className = 'comparison-icon';
            
            forArticleTile.appendChild(emptyIcon);
            forArticleTile.appendChild(icon);

            this.touchPointElement = forArticleTile;
            
            if (!this.touchPointElement) {
                throw new Error("no touchpoint returned from service");
            }

            this.markerElement.parentNode.replaceChild(this.touchPointElement, this.markerElement);
            
            this.initialize();
        }

        private initialize(): void {
            this.toggle();
            this.touchPointElement.querySelector('.comparison-empty-icon').innerHTML = ScaleEmptyIcon + ScaleIcon2021;
            this.touchPointElement.querySelector('.comparison-icon').innerHTML = ScaleIcon2021;

            this.touchPointElement.addEventListener('click', MPCCallLimiter.CallLimiter.debounce(() => {
                this.updateFlyout();
            }, 1000, true).bind(this));
            this.eventBus.subscribe("flyout.product-removed", (salesArticleVariantKey: string) => {
                if (salesArticleVariantKey === this.salesArticleVariantKey) {
                    this.touchPointElement.classList.remove('active');
                }
            });
            this.eventBus.subscribe(["flyout.product-added", "flyout.product-replaced"], (salesArticleVariantKey: string) => {
                if (salesArticleVariantKey === this.salesArticleVariantKey) {
                    this.touchPointElement.classList.add('active');
                }
            });

            if (this.isMobileShop) {
                this.eventBus.subscribe("mobile-toolbar-button.clicked", this.updateVisibility.bind(this));
            }
        }

        private updateVisibility(): void {
            if (this.touchPointElement.classList.contains("ats")) {
                if (this.touchPointElement.parentElement.classList.contains('fas_hidden')) {
                    this.touchPointElement.parentElement.classList.remove('fas_hidden');
                } else {
                    this.touchPointElement.parentElement.classList.add('fas_hidden');
                }
            } else {
                if (this.touchPointElement.classList.contains("visible")) {
                    this.touchPointElement.classList.remove("visible");
                } else {
                    this.touchPointElement.classList.add("visible");
                }
            }
        }

        private updateFlyout(): void {
            if (!this.iFlyoutContentController.checkIsProductAlreadyAdded(this.salesArticleVariantKey)) {
                this.iFlyoutContentController.addProduct(this.salesArticleVariantKey, this.slug, this.origin);
            } else {
                this.iFlyoutContentController.removeProductTP(this.salesArticleVariantKey);
            }
        }

        private toggle(): void {
            if (this.iFlyoutContentController.checkIsProductAlreadyAdded(this.salesArticleVariantKey)) {
                this.touchPointElement.classList.add('active');
            } else {
                this.touchPointElement.classList.remove('active');
            }
        }
    }
}